import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withKeys as _withKeys } from "vue"
import _imports_0 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_1 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_2 from '@/assets/icons/white_publish_24px.png'
import _imports_3 from '@/assets/image/questions_radio.svg'
import _imports_4 from '@/assets/image/questions_checkbox.svg'


const _hoisted_1 = {
  key: 0,
  class: "body-width modal"
}
const _hoisted_2 = { class: "box-card" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_8 = {
  key: 2,
  class: "btn btn-create btn-progress btn-primary disable-true"
}
const _hoisted_9 = {
  key: 1,
  class: "menu-container flex-center"
}
const _hoisted_10 = { class: "body-width-reponsive-max" }
const _hoisted_11 = {
  class: "nav-bar-container flex-center",
  style: {"flex-direction":"row"}
}
const _hoisted_12 = {
  class: "flex-center",
  style: {"width":"30%"}
}
const _hoisted_13 = { class: "label-container" }
const _hoisted_14 = { class: "nav-text-header" }
const _hoisted_15 = { style: {"width":"70%","height":"50px","flex-basis":"100%","display":"flex","align-items":"center","place-content":"flex-end"} }
const _hoisted_16 = { class: "fs-12 fw-700" }
const _hoisted_17 = { class: "fs-12 fw-700" }
const _hoisted_18 = {
  key: 0,
  src: _imports_0,
  class: "icon-20 ml-12",
  alt: "navigate_right_black_24px"
}
const _hoisted_19 = {
  key: 1,
  src: _imports_1,
  class: "icon-20 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_20 = {
  key: 1,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_21 = { class: "fs-12 fw-700" }
const _hoisted_22 = { class: "ml-24 flex-mid" }
const _hoisted_23 = {
  key: 3,
  class: "ml-16 btn02 btn-40 disable-true"
}
const _hoisted_24 = { class: "fs-12 fw-700 mr-16" }
const _hoisted_25 = {
  key: 2,
  class: "body-width",
  id: "assessment_setup_values_and_traits"
}
const _hoisted_26 = { style: {"margin-top":"6.4rem"} }
const _hoisted_27 = {
  class: "body-container-with-step",
  style: {"margin-top":"6.4rem"}
}
const _hoisted_28 = { class: "step-menu-container vertical" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "menu-inner" }
const _hoisted_31 = { class: "step-num" }
const _hoisted_32 = { class: "step-name" }
const _hoisted_33 = {
  key: 0,
  class: "step-desc"
}
const _hoisted_34 = {
  class: "",
  style: {"justify-content":"center"}
}
const _hoisted_35 = {
  key: 0,
  class: "x-card-question-padding box-card mb-20 bg-FFFFFF"
}
const _hoisted_36 = {
  class: "flex-center",
  style: {"padding":"24px","justify-content":"space-between"}
}
const _hoisted_37 = { class: "title-box" }
const _hoisted_38 = ["placeholder"]
const _hoisted_39 = ["placeholder"]
const _hoisted_40 = ["src"]
const _hoisted_41 = {
  key: 1,
  class: "x-card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_42 = { style: {"padding":"24px 48px 24px 48px"} }
const _hoisted_43 = {
  key: 0,
  class: "fs-24 fc-1B1C1E fw-900"
}
const _hoisted_44 = {
  key: 1,
  class: "fs-16 mt-12"
}
const _hoisted_45 = { class: "" }
const _hoisted_46 = { class: "scenarios-container" }
const _hoisted_47 = {
  class: "relative",
  style: {"width":"16px","height":"16px","top":"3px"}
}
const _hoisted_48 = {
  class: "handle",
  width: "7",
  height: "11",
  viewBox: "0 0 7 11",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  style: {"margin":"2.5 4px"}
}
const _hoisted_49 = {
  class: "index flex-mid fc-primary relative",
  style: {"width":"2.4rem","height":"16px","top":"3px"}
}
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = { class: "display: flex; align-items: center; margin-bottom: 10px;" }
const _hoisted_53 = { class: "type-content" }
const _hoisted_54 = ["onUpdate:modelValue", "placeholder", "rows"]
const _hoisted_55 = ["placeholder", "onUpdate:modelValue"]
const _hoisted_56 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_57 = { key: 1 }
const _hoisted_58 = { class: "display: flex; align-items: center; margin-bottom: 10px;" }
const _hoisted_59 = { class: "type-content" }
const _hoisted_60 = ["onUpdate:modelValue", "placeholder", "rows"]
const _hoisted_61 = ["placeholder", "onUpdate:modelValue"]
const _hoisted_62 = ["id", "name", "onUpdate:modelValue", "placeholder"]
const _hoisted_63 = ["onClick"]
const _hoisted_64 = { key: 0 }
const _hoisted_65 = ["name", "onKeyup", "placeholder"]
const _hoisted_66 = { class: "add-new-choice-container" }
const _hoisted_67 = ["onClick"]
const _hoisted_68 = { class: "error-msg" }
const _hoisted_69 = { key: 2 }
const _hoisted_70 = { class: "display: flex; align-items: center; margin-bottom: 10px;" }
const _hoisted_71 = { class: "type-content" }
const _hoisted_72 = ["onUpdate:modelValue", "placeholder", "rows"]
const _hoisted_73 = ["placeholder", "onUpdate:modelValue"]
const _hoisted_74 = ["id", "name", "onUpdate:modelValue", "placeholder"]
const _hoisted_75 = ["onClick"]
const _hoisted_76 = { key: 0 }
const _hoisted_77 = ["name", "onKeyup", "placeholder"]
const _hoisted_78 = { class: "add-new-choice-container" }
const _hoisted_79 = ["onClick"]
const _hoisted_80 = { class: "error-msg" }
const _hoisted_81 = ["onClick"]
const _hoisted_82 = {
  key: 2,
  class: "x-card-question-padding box-card mb-20 bg-FFFFFF"
}
const _hoisted_83 = {
  class: "flex-center",
  style: {"padding":"24px","justify-content":"space-between"}
}
const _hoisted_84 = { class: "title-box" }
const _hoisted_85 = { class: "title has-value" }
const _hoisted_86 = { class: "description has-value" }
const _hoisted_87 = {
  key: 3,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_88 = { class: "max-width-container" }
const _hoisted_89 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_90 = { class: "fs-16 mt-12" }
const _hoisted_91 = { class: "" }
const _hoisted_92 = { class: "mt-18" }
const _hoisted_93 = {
  class: "tutorial",
  style: {"margin-top":"6rem"}
}
const _hoisted_94 = { class: "select-ab-btn active color-15CE95 flex-center" }
const _hoisted_95 = { class: "text-description flex-center" }
const _hoisted_96 = { class: "select-ab-btn active color-FAA13A flex-center" }
const _hoisted_97 = { class: "text-description flex-center" }
const _hoisted_98 = { class: "select-ab-btn active color-FA8B3A flex-center" }
const _hoisted_99 = { class: "text-description flex-center" }
const _hoisted_100 = { class: "select-ab-btn active color-FA5F3A flex-center" }
const _hoisted_101 = { class: "text-description flex-center" }
const _hoisted_102 = { class: "select-ab-btn active color-FA3A3A flex-center" }
const _hoisted_103 = { class: "text-description flex-center" }
const _hoisted_104 = {
  key: 4,
  class: "x-card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_105 = { style: {"padding":"24px 48px 24px 48px"} }
const _hoisted_106 = {
  key: 0,
  class: "fs-24 fc-1B1C1E fw-900"
}
const _hoisted_107 = {
  key: 1,
  class: "fs-16 mt-12"
}
const _hoisted_108 = { class: "" }
const _hoisted_109 = {
  class: "scenarios-container",
  style: {"grid-template-columns":"2.4rem auto 3.2rem !important"}
}
const _hoisted_110 = {
  class: "index flex-mid fc-primary relative",
  style: {"width":"2.4rem","height":"16px","top":"3px"}
}
const _hoisted_111 = { class: "disabled-data-preview" }
const _hoisted_112 = { key: 0 }
const _hoisted_113 = { class: "preview-content-text" }
const _hoisted_114 = { class: "display: flex; align-items: center; margin-bottom: 10px;" }
const _hoisted_115 = { class: "type-content" }
const _hoisted_116 = { class: "text-question" }
const _hoisted_117 = {
  key: 0,
  class: "input-answer-content",
  style: {"width":"100% !important"}
}
const _hoisted_118 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_119 = { key: 1 }
const _hoisted_120 = { class: "preview-content-text" }
const _hoisted_121 = { class: "type-content" }
const _hoisted_122 = { class: "text-question" }
const _hoisted_123 = {
  key: 0,
  class: "input-answer-content",
  style: {"width":"100% !important"}
}
const _hoisted_124 = ["id", "value"]
const _hoisted_125 = { key: 2 }
const _hoisted_126 = { class: "preview-content-text" }
const _hoisted_127 = { class: "type-content" }
const _hoisted_128 = { class: "text-question" }
const _hoisted_129 = {
  key: 0,
  class: "input-answer-content",
  style: {"width":"100% !important"}
}
const _hoisted_130 = ["id", "value"]
const _hoisted_131 = {
  key: 3,
  class: "body-width modal"
}
const _hoisted_132 = { class: "box-card" }
const _hoisted_133 = { class: "modal-title" }
const _hoisted_134 = { class: "modal-body" }
const _hoisted_135 = { class: "modal-footer" }
const _hoisted_136 = { class: "remove-header remove-padding-modal" }
const _hoisted_137 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_138 = { class: "fs-20 fw-700" }
const _hoisted_139 = {
  class: "fs-14 mt-20",
  style: {"color":"#696c80"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step == 'get_start')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("setup.customSurvey.Setup_your_Custom_Survey")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("setup.customSurvey.Add_your_own_set_of_questions")), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                false
                  ? (_openBlock(), _createBlock(_component_el_select, {
                      key: 0,
                      modelValue: _ctx.selectedExistsTemplate,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedExistsTemplate) = $event)),
                      class: "ep-select-44",
                      style: {"width":"42rem","margin-top":"6.4rem"},
                      placeholder: _ctx.$t('setup._Use_the_same_setup')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item._id,
                            label: item.form_name,
                            value: item._id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"]))
                  : _createCommentVNode("", true),
                (!_ctx.getStartedButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getStarted())),
                      class: "btn btn-primary"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      (_ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                        : (_openBlock(), _createElementBlock("img", _hoisted_7))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      _cache[22] || (_cache[22] = _createElementVNode("svg", {
                        version: "1.1",
                        id: "L9",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 100 100",
                        "enable-background": "new 0 0 0 0",
                        "xml:space": "preserve",
                        class: "icon-24"
                      }, [
                        _createElementVNode("path", {
                          fill: "#aaa",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ])
                      ], -1))
                    ])),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-default"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[23] || (_cache[23] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.backToLastPage && _ctx.backToLastPage(...args))),
                  class: "btn-left"
                }, _cache[24] || (_cache[24] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "icons_navigate_right_black_24px",
                    class: "icon-16 animetion",
                    style: {"transform":"rotate(180deg)"}
                  }, null, -1)
                ])),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("setup.customSurvey.Setup_your_Custom_Survey")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                (!_ctx.isFirstStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.gotoPreviousStep && _ctx.gotoPreviousStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-previous", `${_ctx.canPreviousStep ? '' : 'disable-true'}`])
                    }, [
                      _cache[25] || (_cache[25] = _createElementVNode("img", {
                        src: _imports_0,
                        class: "icon-20 mr-12",
                        alt: "navigate_right_black_24px",
                        style: {"transform":"rotate(180deg)"}
                      }, null, -1)),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("create.Previous")), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (!_ctx.isLastStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.gotoNextStep && _ctx.gotoNextStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-next", {
              'btn-primary': _ctx.canNextStep && _ctx.publishSubmitStatus,
              'disable-true': !_ctx.checkStepCompletion(),
            }])
                    }, [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("create.Next")), 1),
                      (_ctx.publishSubmitStatus)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                              : (_openBlock(), _createElementBlock("img", _hoisted_19))
                          ], 64))
                        : (_openBlock(), _createElementBlock("svg", _hoisted_20, _cache[26] || (_cache[26] = [
                            _createElementVNode("path", {
                              fill: "#aaa",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            }, [
                              _createElementVNode("animateTransform", {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite"
                              })
                            ], -1)
                          ])))
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.isLastStep && _ctx.publishSubmitStatus)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.publishSubmit && _ctx.publishSubmit(...args))),
                      class: "ml-16 btn02 btn-40 btn-primary"
                    }, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("create.Publish")), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("img", {
                          src: _imports_2,
                          class: "icon-20",
                          style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                          alt: "icons_white_publish_24px"
                        }, null, 4)
                      ])
                    ]))
                  : (_ctx.isLastStep)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("create.Publish")), 1),
                        _cache[27] || (_cache[27] = _createElementVNode("svg", {
                          width: "3rem",
                          height: "3rem",
                          version: "1.1",
                          id: "L9",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 100 100",
                          "enable-background": "new 0 0 0 0",
                          "xml:space": "preserve"
                        }, [
                          _createElementVNode("path", {
                            fill: "#aaa",
                            d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          }, [
                            _createElementVNode("animateTransform", {
                              attributeName: "transform",
                              attributeType: "XML",
                              type: "rotate",
                              dur: "1s",
                              from: "0 50 50",
                              to: "360 50 50",
                              repeatCount: "indefinite"
                            })
                          ])
                        ], -1))
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `menu_${i}`,
                    class: _normalizeClass(`menu ${_ctx.tabNextStepCls(item.step)}`),
                    style: _normalizeStyle(`display: ${item.visible === false ? 'none' : ''}`),
                    onClick: ($event: any) => (_ctx.gotoStep(item.step))
                  }, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, _toDisplayString(item.name), 1),
                      _createElementVNode("div", _hoisted_32, _toDisplayString(item.title), 1),
                      (item.desc)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(item.desc), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 14, _hoisted_29))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_34, [
                (_ctx.step == 'setup_aceetable_behaivors_scenarios')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["title", _ctx.inputCls('title')]),
                            onInput: _cache[8] || (_cache[8] = (e) => _ctx.change(e, 'title')),
                            contenteditable: "true",
                            placeholder: _ctx.$t('setup.customSurvey.Title_of_Section')
                          }, _toDisplayString(_ctx.title), 43, _hoisted_38),
                          _createElementVNode("div", {
                            class: _normalizeClass(["description", _ctx.inputCls('description')]),
                            onInput: _cache[9] || (_cache[9] = (e) => _ctx.change(e, 'description')),
                            contenteditable: "true",
                            placeholder: _ctx.$t('setup.customSurvey.Short_Description')
                          }, _toDisplayString(_ctx.description), 43, _hoisted_39)
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_el_dropdown, {
                            disabled: _ctx.dropdownDisabled,
                            class: _normalizeClass({ 'disabled-cursor': _ctx.dropdownDisabled }),
                            "popper-class": "mc-popper-class"
                          }, {
                            dropdown: _withCtx(() => [
                              _createVNode(_component_el_dropdown_menu, { class: "mc-dropdown-menu" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionTypes, (types) => {
                                    return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                      key: types.type,
                                      value: types.type,
                                      onClick: ($event: any) => (_ctx.onClickSelectedType(types.type)),
                                      "popper-class": "x-mc-popper-class",
                                      class: "mc-dropdown-item"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("img", {
                                          src: _ctx.iconUrl(types.icon),
                                          alt: "My Core Values",
                                          style: {"margin-right":"10px"}
                                        }, null, 8, _hoisted_40),
                                        _createTextVNode(" " + _toDisplayString(types.label), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                class: "btn btn-primary btn-40 ml-auto",
                                style: {"border-radius":"1.2rem","padding":"0 2.4rem","box-shadow":"none","font-weight":"700","font-size":"12px","line-height":"110%","color":"white"}
                              }, {
                                default: _withCtx(() => [
                                  _cache[28] || (_cache[28] = _createElementVNode("div", { class: "relative mr-14" }, [
                                    _createElementVNode("svg", {
                                      width: "9.33",
                                      height: "9.33",
                                      viewBox: "0 0 10 10",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M4.33301 4.33398V0.333984H5.66634V4.33398H9.66634V5.66732H5.66634V9.66732H4.33301V5.66732H0.333008V4.33398H4.33301Z",
                                        fill: "white"
                                      })
                                    ])
                                  ], -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t("setup.customSurvey.Question_Block")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["disabled", "class"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'setup_aceetable_behaivors_scenarios')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                      _createElementVNode("div", _hoisted_42, [
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_43, _toDisplayString(_ctx.$t(
                    "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
                  )), 1))
                          : _createCommentVNode("", true),
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                              _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.$t(
                      "setup.acceptableBehaviors.Review_the_acceptable_behaviors"
                    )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_draggable, {
                              modelValue: _ctx.customSurvey,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customSurvey) = $event)),
                              group: "people",
                              onUpdate: _ctx.updateCradList,
                              onStart: _cache[17] || (_cache[17] = ($event: any) => (_ctx.drag = true)),
                              onEnd: _cache[18] || (_cache[18] = ($event: any) => (_ctx.drag = false)),
                              "item-key": "id",
                              handle: ".handle"
                            }, {
                              item: _withCtx(({ element, index }) => [
                                _createElementVNode("div", _hoisted_46, [
                                  _createElementVNode("div", _hoisted_47, [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_48, _cache[29] || (_cache[29] = [
                                      _createElementVNode("path", {
                                        d: "M4 9.25C4 8.55964 4.55964 8 5.25 8C5.94036 8 6.5 8.55964 6.5 9.25C6.5 9.94036 5.94036 10.5 5.25 10.5C4.55964 10.5 4 9.94036 4 9.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M0 9.25C0 8.55964 0.559644 8 1.25 8C1.94036 8 2.5 8.55964 2.5 9.25C2.5 9.94036 1.94036 10.5 1.25 10.5C0.559644 10.5 0 9.94036 0 9.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M4 5.25C4 4.55964 4.55964 4 5.25 4C5.94036 4 6.5 4.55964 6.5 5.25C6.5 5.94036 5.94036 6.5 5.25 6.5C4.55964 6.5 4 5.94036 4 5.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M0 5.25C0 4.55964 0.559644 4 1.25 4C1.94036 4 2.5 4.55964 2.5 5.25C2.5 5.94036 1.94036 6.5 1.25 6.5C0.559644 6.5 0 5.94036 0 5.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M4 1.25C4 0.559644 4.55964 0 5.25 0C5.94036 0 6.5 0.559644 6.5 1.25C6.5 1.94036 5.94036 2.5 5.25 2.5C4.55964 2.5 4 1.94036 4 1.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M0 1.25C0 0.559644 0.559644 0 1.25 0C1.94036 0 2.5 0.559644 2.5 1.25C2.5 1.94036 1.94036 2.5 1.25 2.5C0.559644 2.5 0 1.94036 0 1.25Z",
                                        fill: "#696C80"
                                      }, null, -1)
                                    ])))
                                  ]),
                                  _createElementVNode("div", _hoisted_49, _toDisplayString(("0" + String(index + 1)).substring(
                              1 + index > 9 ? 1 : 0
                            )), 1),
                                  (element.type)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                                        (element.type == 'open-ended')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                                              _createElementVNode("div", null, [
                                                _createElementVNode("div", _hoisted_52, [
                                                  _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("setup.customSurvey.Open_ended")), 1),
                                                  _withDirectives(_createElementVNode("textarea", {
                                                    class: "textarea-content",
                                                    "onUpdate:modelValue": ($event: any) => ((element.question) = $event),
                                                    placeholder: 
                                    _ctx.$t(
                                      'setup.customSurvey.What_makes_you_happiest_at_work'
                                    )
                                  ,
                                                    onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.autoExpand($event))),
                                                    rows: element.question ? '' : 1
                                                  }, "                                ", 40, _hoisted_54), [
                                                    [_vModelText, element.question]
                                                  ]),
                                                  _withDirectives(_createElementVNode("input", {
                                                    type: "text",
                                                    name: "description",
                                                    class: "input-answer-content",
                                                    placeholder: 
                                    _ctx.$t(
                                      'setup.customSurvey.Description_optional'
                                    )
                                  ,
                                                    "onUpdate:modelValue": ($event: any) => ((element.description) = $event),
                                                    style: {"margin-bottom":"16px","width":"100%"}
                                                  }, null, 8, _hoisted_55), [
                                                    [_vModelText, element.description]
                                                  ]),
                                                  _withDirectives(_createElementVNode("textarea", {
                                                    class: "textarea-content",
                                                    style: {"font-weight":"400 !important"},
                                                    "onUpdate:modelValue": ($event: any) => ((element.answer) = $event),
                                                    readonly: true,
                                                    placeholder: 
                                    _ctx.$t(
                                      'setup.customSurvey.Type_your_answer_here'
                                    )
                                  ,
                                                    onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.autoExpand($event)))
                                                  }, "                                ", 40, _hoisted_56), [
                                                    [_vModelText, element.answer]
                                                  ])
                                                ])
                                              ])
                                            ]))
                                          : (element.type === 'multiple-choice')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                                                _createElementVNode("div", null, [
                                                  _createElementVNode("div", _hoisted_58, [
                                                    _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t("setup.customSurvey.Multiple_Choice")), 1),
                                                    _withDirectives(_createElementVNode("textarea", {
                                                      class: "textarea-content",
                                                      "onUpdate:modelValue": ($event: any) => ((element.question) = $event),
                                                      placeholder: 
                                    _ctx.$t(
                                      'setup.customSurvey.What_makes_you_happiest_at_work'
                                    )
                                  ,
                                                      onInput: _cache[12] || (_cache[12] = ($event: any) => (_ctx.autoExpand($event))),
                                                      rows: element.question ? '' : 1
                                                    }, "                                ", 40, _hoisted_60), [
                                                      [_vModelText, element.question]
                                                    ]),
                                                    _withDirectives(_createElementVNode("input", {
                                                      type: "text",
                                                      name: "description",
                                                      class: "input-answer-content",
                                                      placeholder: 
                                    _ctx.$t(
                                      'setup.customSurvey.Description_optional'
                                    )
                                  ,
                                                      "onUpdate:modelValue": ($event: any) => ((element.description) = $event),
                                                      style: {"margin-bottom":"16px","width":"100%"}
                                                    }, null, 8, _hoisted_61), [
                                                      [_vModelText, element.description]
                                                    ]),
                                                    _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.choices, (choice, indexChoice) => {
                                                      return (_openBlock(), _createElementBlock("div", { key: indexChoice }, [
                                                        _cache[31] || (_cache[31] = _createElementVNode("span", { class: "custom-dot" }, [
                                                          _createElementVNode("img", { src: _imports_3 })
                                                        ], -1)),
                                                        _withDirectives(_createElementVNode("input", {
                                                          class: "input-answer-content",
                                                          type: "text",
                                                          id: `choice_${indexChoice}`,
                                                          name: `choice_Radio${index}${indexChoice}`,
                                                          "onUpdate:modelValue": ($event: any) => ((choice.value) = $event),
                                                          placeholder: choice.placeholder,
                                                          onInput: _cache[13] || (_cache[13] = ($event: any) => (_ctx.checkStepCompletion()))
                                                        }, null, 40, _hoisted_62), [
                                                          [_vModelText, choice.value]
                                                        ]),
                                                        (element.choices.length > 0)
                                                          ? (_openBlock(), _createElementBlock("svg", {
                                                              key: 0,
                                                              width: "10",
                                                              height: "10",
                                                              viewBox: "0 0 10 10",
                                                              fill: "none",
                                                              xmlns: "http://www.w3.org/2000/svg",
                                                              style: {"margin-left":"10px","vertical-align":"middle","cursor":"pointer"},
                                                              onClick: ($event: any) => (_ctx.deleteChoice(index, indexChoice))
                                                            }, _cache[30] || (_cache[30] = [
                                                              _createElementVNode("path", {
                                                                d: "M5.00047 4.05767L8.30027 0.757812L9.24306 1.70062L5.94327 5.00047L9.24306 8.30027L8.30027 9.24306L5.00047 5.94327L1.70062 9.24306L0.757812 8.30027L4.05767 5.00047L0.757812 1.70062L1.70062 0.757812L5.00047 4.05767Z",
                                                                fill: "#696C80"
                                                              }, null, -1)
                                                            ]), 8, _hoisted_63))
                                                          : _createCommentVNode("", true)
                                                      ]))
                                                    }), 128)),
                                                    false
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                                                          _cache[32] || (_cache[32] = _createElementVNode("span", { class: "custom-dot" }, [
                                                            _createElementVNode("img", { src: _imports_3 })
                                                          ], -1)),
                                                          _createElementVNode("input", {
                                                            class: "input-answer-content",
                                                            type: "text",
                                                            name: `choice_Radio${index}`,
                                                            ref: "newChoiceInputMultiChoice",
                                                            onKeyup: _withKeys(($event: any) => (
                                      _ctx.addChoice(index, 'multiple-choice')
                                    ), ["enter"]),
                                                            placeholder: 
                                      _ctx.$t('setup.customSurvey.Add_Option')
                                    
                                                          }, null, 40, _hoisted_65)
                                                        ]))
                                                      : _createCommentVNode("", true),
                                                    _createElementVNode("div", _hoisted_66, [
                                                      _createElementVNode("a", {
                                                        href: "javascript:void(0)",
                                                        onClick: ($event: any) => (_ctx.addNewChoice(index))
                                                      }, _toDisplayString(_ctx.$t("setup.customSurvey.Add_Option")), 9, _hoisted_67),
                                                      _createElementVNode("div", _hoisted_68, _toDisplayString(_ctx.addMultipleChoiceErrorMsg), 1)
                                                    ])
                                                  ])
                                                ])
                                              ]))
                                            : (element.type === 'multiple-select')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                                                  _createElementVNode("div", null, [
                                                    _createElementVNode("div", _hoisted_70, [
                                                      _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("setup.customSurvey.Multiple_Select")), 1),
                                                      _withDirectives(_createElementVNode("textarea", {
                                                        class: "textarea-content",
                                                        "onUpdate:modelValue": ($event: any) => ((element.question) = $event),
                                                        placeholder: 
                                    _ctx.$t(
                                      'setup.customSurvey.What_makes_you_happiest_at_work'
                                    )
                                  ,
                                                        onInput: _cache[14] || (_cache[14] = ($event: any) => (_ctx.autoExpand($event))),
                                                        rows: element.question ? '' : 1
                                                      }, "                                ", 40, _hoisted_72), [
                                                        [_vModelText, element.question]
                                                      ]),
                                                      _withDirectives(_createElementVNode("input", {
                                                        type: "text",
                                                        name: "description",
                                                        class: "input-answer-content",
                                                        placeholder: 
                                    _ctx.$t(
                                      'setup.customSurvey.Description_optional'
                                    )
                                  ,
                                                        style: {"margin-bottom":"16px","width":"100%"},
                                                        "onUpdate:modelValue": ($event: any) => ((element.description) = $event)
                                                      }, null, 8, _hoisted_73), [
                                                        [_vModelText, element.description]
                                                      ]),
                                                      _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.choices, (choice, indexChoice) => {
                                                        return (_openBlock(), _createElementBlock("div", { key: indexChoice }, [
                                                          _cache[35] || (_cache[35] = _createElementVNode("span", { class: "custom-checkbox" }, [
                                                            _createElementVNode("img", { src: _imports_4 })
                                                          ], -1)),
                                                          _withDirectives(_createElementVNode("input", {
                                                            class: "input-answer-content",
                                                            type: "text",
                                                            id: `choice_checkbox${indexChoice}`,
                                                            name: `choice_checkbox${index}${indexChoice}`,
                                                            "onUpdate:modelValue": ($event: any) => ((choice.value) = $event),
                                                            placeholder: choice.placeholder,
                                                            onInput: _cache[15] || (_cache[15] = ($event: any) => (_ctx.checkStepCompletion()))
                                                          }, null, 40, _hoisted_74), [
                                                            [_vModelText, choice.value]
                                                          ]),
                                                          (element.choices.length > 0)
                                                            ? (_openBlock(), _createElementBlock("svg", {
                                                                key: 0,
                                                                width: "10",
                                                                height: "10",
                                                                viewBox: "0 0 10 10",
                                                                fill: "none",
                                                                xmlns: "http://www.w3.org/2000/svg",
                                                                style: {"margin-left":"10px","vertical-align":"middle","cursor":"pointer"},
                                                                onClick: ($event: any) => (_ctx.deleteChoice(index, indexChoice))
                                                              }, _cache[34] || (_cache[34] = [
                                                                _createElementVNode("path", {
                                                                  d: "M5.00047 4.05767L8.30027 0.757812L9.24306 1.70062L5.94327 5.00047L9.24306 8.30027L8.30027 9.24306L5.00047 5.94327L1.70062 9.24306L0.757812 8.30027L4.05767 5.00047L0.757812 1.70062L1.70062 0.757812L5.00047 4.05767Z",
                                                                  fill: "#696C80"
                                                                }, null, -1)
                                                              ]), 8, _hoisted_75))
                                                            : _createCommentVNode("", true)
                                                        ]))
                                                      }), 128)),
                                                      false
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                                            _cache[36] || (_cache[36] = _createElementVNode("span", { class: "custom-checkbox" }, [
                                                              _createElementVNode("img", { src: _imports_4 })
                                                            ], -1)),
                                                            _createElementVNode("input", {
                                                              class: "input-answer-content",
                                                              type: "text",
                                                              name: `choice_checkbox${index}`,
                                                              ref: "newChoiceInputMultiSelect",
                                                              onKeyup: _withKeys(($event: any) => (
                                      _ctx.addChoice(index, 'multiple-select')
                                    ), ["enter"]),
                                                              placeholder: 
                                      _ctx.$t('setup.customSurvey.Add_Option')
                                    
                                                            }, null, 40, _hoisted_77)
                                                          ]))
                                                        : _createCommentVNode("", true),
                                                      _createElementVNode("div", _hoisted_78, [
                                                        _createElementVNode("a", {
                                                          href: "javascript:void(0)",
                                                          onClick: ($event: any) => (_ctx.addNewChoice(index))
                                                        }, _toDisplayString(_ctx.$t("setup.customSurvey.Add_Option")), 9, _hoisted_79),
                                                        _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.addMultipleSelectErrorMsg), 1)
                                                      ])
                                                    ])
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", {
                                    onClick: ($event: any) => (_ctx.removeScenario(index)),
                                    class: "relative flex-mid remove",
                                    style: {"width":"16px","height":"16px","top":"3px"}
                                  }, _cache[38] || (_cache[38] = [
                                    _createElementVNode("svg", {
                                      width: "9",
                                      height: "9",
                                      viewBox: "0 0 9 9",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M4.74265 3.29985L8.04245 0L8.98525 0.942807L5.68545 4.24265L8.98525 7.54245L8.04245 8.48525L4.74265 5.18545L1.44281 8.48525L0.5 7.54245L3.79985 4.24265L0.5 0.942807L1.44281 0L4.74265 3.29985Z",
                                        fill: "#696C80"
                                      })
                                    ], -1)
                                  ]), 8, _hoisted_81)
                                ])
                              ]),
                              _: 1
                            }, 8, ["modelValue", "onUpdate"])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'Acceptable_Behaviors_Setup_AllInOne')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                      _createElementVNode("div", _hoisted_83, [
                        _createElementVNode("div", _hoisted_84, [
                          _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.title), 1),
                          _createElementVNode("div", _hoisted_86, _toDisplayString(_ctx.description), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'Evaluating_Acceptable_Behaviors_Tutorial')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                      _createElementVNode("div", _hoisted_88, [
                        _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.$t(
                    "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
                  )), 1),
                        _createElementVNode("div", _hoisted_90, [
                          _createElementVNode("div", _hoisted_91, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.In_the_workplace_it_is_not_uncommon"
                    )), 1),
                          _createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.Please_provide_a_rating_for_each"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_93, [
                          _createElementVNode("div", _hoisted_94, [
                            _cache[39] || (_cache[39] = _createElementVNode("div", { class: "value-box flex-mid" }, "0", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Acceptable")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_acceptable"
                    )), 1),
                          _createElementVNode("div", _hoisted_96, [
                            _cache[40] || (_cache[40] = _createElementVNode("div", { class: "value-box flex-mid" }, "1", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Frowned_Upon")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_97, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_not_acceptable"
                    )), 1),
                          _createElementVNode("div", _hoisted_98, [
                            _cache[41] || (_cache[41] = _createElementVNode("div", { class: "value-box flex-mid" }, "2", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Actively_Discouraged")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_99, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_unacceptable_Discouraged"
                    )), 1),
                          _createElementVNode("div", _hoisted_100, [
                            _cache[42] || (_cache[42] = _createElementVNode("div", { class: "value-box flex-mid" }, "3", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Reprimanded")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_101, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_unacceptable_Reprimanded"
                    )), 1),
                          _createElementVNode("div", _hoisted_102, [
                            _cache[43] || (_cache[43] = _createElementVNode("div", { class: "value-box flex-mid" }, "4", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Zero_Tolerance_Policy")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_103, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_irreprehensible"
                    )), 1)
                        ])
                      ])
                    ]))
                  : (_ctx.step == 'Acceptable_Behaviors_Setup_AllInOne')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_104, [
                        _cache[49] || (_cache[49] = _createElementVNode("div", null, null, -1)),
                        _createElementVNode("div", _hoisted_105, [
                          false
                            ? (_openBlock(), _createElementBlock("div", _hoisted_106, _toDisplayString(_ctx.$t(
                    "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
                  )), 1))
                            : _createCommentVNode("", true),
                          false
                            ? (_openBlock(), _createElementBlock("div", _hoisted_107, [
                                _createElementVNode("div", _hoisted_108, _toDisplayString(_ctx.$t(
                      "setup.acceptableBehaviors.Review_the_acceptable_behaviors"
                    )), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customSurvey, (element, index) => {
                              return (_openBlock(), _createElementBlock("div", { key: index }, [
                                _createElementVNode("div", _hoisted_109, [
                                  _createElementVNode("div", _hoisted_110, _toDisplayString(("0" + String(index + 1)).substring(
                          1 + index > 9 ? 1 : 0
                        )), 1),
                                  _createElementVNode("div", _hoisted_111, [
                                    (element.type == 'open-ended')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_112, [
                                          _createElementVNode("div", _hoisted_113, [
                                            _createElementVNode("div", _hoisted_114, [
                                              _createElementVNode("div", _hoisted_115, _toDisplayString(_ctx.$t("setup.customSurvey.Open_ended")), 1),
                                              _createElementVNode("div", _hoisted_116, _toDisplayString(element.question), 1),
                                              (
                                element.description !== null &&
                                element.description !== ''
                              )
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_117, _toDisplayString(element.description), 1))
                                                : _createCommentVNode("", true),
                                              _cache[44] || (_cache[44] = _createElementVNode("div", { style: {"margin-bottom":"16px"} }, null, -1)),
                                              _withDirectives(_createElementVNode("textarea", {
                                                class: "textarea-content",
                                                style: {"font-weight":"400 !important"},
                                                "onUpdate:modelValue": ($event: any) => ((element.answer) = $event),
                                                placeholder: 
                                _ctx.$t('setup.customSurvey.Type_your_answer_here')
                              ,
                                                disabled: ""
                                              }, null, 8, _hoisted_118), [
                                                [_vModelText, element.answer]
                                              ])
                                            ])
                                          ])
                                        ]))
                                      : (element.type == 'multiple-choice')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_119, [
                                            _createElementVNode("div", _hoisted_120, [
                                              _createElementVNode("div", _hoisted_121, _toDisplayString(_ctx.$t("setup.customSurvey.Multiple_Choice")), 1),
                                              _createElementVNode("div", _hoisted_122, _toDisplayString(element.question), 1),
                                              (
                              element.description !== null &&
                              element.description !== ''
                            )
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_123, _toDisplayString(element.description), 1))
                                                : _createCommentVNode("", true),
                                              _cache[46] || (_cache[46] = _createElementVNode("div", { style: {"margin-bottom":"16px"} }, null, -1)),
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.choices, (choice, indexChoice) => {
                                                return (_openBlock(), _createElementBlock("div", { key: indexChoice }, [
                                                  _cache[45] || (_cache[45] = _createElementVNode("span", { class: "custom-dot" }, [
                                                    _createElementVNode("img", { src: _imports_3 })
                                                  ], -1)),
                                                  _createElementVNode("input", {
                                                    class: "input-answer-content",
                                                    type: "text",
                                                    id: `choice_${indexChoice}`,
                                                    name: "question",
                                                    value: choice.value,
                                                    disabled: ""
                                                  }, null, 8, _hoisted_124)
                                                ]))
                                              }), 128))
                                            ])
                                          ]))
                                        : (element.type == 'multiple-select')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_125, [
                                              _createElementVNode("div", _hoisted_126, [
                                                _createElementVNode("div", _hoisted_127, _toDisplayString(_ctx.$t("setup.customSurvey.Multiple_Select")), 1),
                                                _createElementVNode("div", _hoisted_128, _toDisplayString(element.question), 1),
                                                (
                              element.description !== null &&
                              element.description !== ''
                            )
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_129, _toDisplayString(element.description), 1))
                                                  : _createCommentVNode("", true),
                                                _cache[48] || (_cache[48] = _createElementVNode("div", { style: {"margin-bottom":"16px"} }, null, -1)),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.choices, (choice, indexChoice) => {
                                                  return (_openBlock(), _createElementBlock("div", { key: indexChoice }, [
                                                    _cache[47] || (_cache[47] = _createElementVNode("span", { class: "custom-checkbox" }, [
                                                      _createElementVNode("img", { src: _imports_4 })
                                                    ], -1)),
                                                    _createElementVNode("input", {
                                                      class: "input-answer-content",
                                                      type: "text",
                                                      id: `choice_${indexChoice}`,
                                                      name: "question",
                                                      value: choice.value,
                                                      disabled: ""
                                                    }, null, 8, _hoisted_130)
                                                  ]))
                                                }), 128))
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                  ])
                                ])
                              ]))
                            }), 128))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step == 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_131, [
          _createElementVNode("div", _hoisted_132, [
            _createElementVNode("div", _hoisted_133, _toDisplayString(_ctx.$t("setup.customSurvey.Setup_Completed")), 1),
            _createElementVNode("div", _hoisted_134, _toDisplayString(_ctx.$t("setup.customSurvey.Thank_you_for_setting_up")), 1),
            _createElementVNode("div", _hoisted_135, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-primary"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close_Window")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[50] || (_cache[50] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_136, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.addAccepTableModalStatus,
        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.addAccepTableModalStatus) = $event)),
        "custom-class": "modal",
        width: "560px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_137, [
            _createElementVNode("div", _hoisted_138, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Add_a_workplace_scenario")), 1),
            _createElementVNode("div", _hoisted_139, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Add_a_scenario_that")), 1)
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}